<template>
  <div class="flex flex-col px-4 pt-2 pb-4 text-sm font-light">
    <div class="flow-row flex w-full">
      <div
        class="flex-grow text-base font-bold"
        v-if="findIndexByPropertyTypeId(device.properties, devicePropertyManufacturerTpyeId) != -1"
      >
        {{ getFormattedValue(device.properties.find((p) => p.property_type_id === devicePropertyManufacturerTpyeId)) }}
        {{
          getFormattedValue(device.properties.find((p) => p.property_type_id === devicePropertyTypeDesignationTpyeId))
        }}
      </div>
      <div v-else class="flex-grow"></div>
      <div class="flex-grow-0 rounded-full p-1">
        <img
          src="@/assets/info_24x24.png"
          class="h-4 w-4 cursor-pointer"
          :class="{ 'h-5 w-5': isSmallScreen }"
          @click="openDetailModal"
        />
      </div>
      <div
        class="flex-grow-0 rounded-full"
        v-if="!isSmallScreen && !device.properties.find((p) => p.property_type_id === hideReplayTypeId)?.value_boolean"
      >
        <router-link :to="`/replay/${device.id}`">
          <img src="@/assets/replay.png" />
        </router-link>
      </div>
    </div>

    <div class="flex flex-row justify-between pt-2" v-for="supported_properties_row in supported_properties">
      <div
        v-for="supported_property in supported_properties_row"
        v-tooltip="{ value: getLabel(supported_property), disabled: supported_property.on_summary_show_label }"
        class="cursor-default"
      >
        <span v-if="supported_property.on_summary_show_label" class="text-xs text-primary"
          >{{ getLabel(supported_property) }}:</span
        >
        {{ getFormattedValue(findPropertyByPropertyTypeId(device.properties, supported_property.property_type_id)) }}
      </div>
    </div>

    <!-- device position -->
    <div class="flex flex-row pt-2">
      <div>
        <svg
          class="h-8 w-4 cursor-pointer text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="copyGoogleMapsLink"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </div>
      <div class="flex-col pl-2">
        <div class="text-sm">
          {{ device.position_city
          }}<span v-if="device.position_streetname" class="text-sm"
            >, {{ device.position_streetname }} {{ device.position_streetnumber }}</span
          >
        </div>
        <div class="text-xs">(letzte Meldung: {{ formatDateTime(device.updated) }})</div>
      </div>
    </div>

    <div v-if="nextMaintenanceDate" class="flex flex-row pt-2" v-tooltip="'nächster Termin'">
      <div>
        <svg
          class="h-4 w-4 text-black"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"
          />
        </svg>
      </div>
      <div class="pl-2">
        {{ getFormattedValue(nextMaintenanceDate) }}
        ({{ getLabelById(nextMaintenanceDate.property_type_id) }})
      </div>
    </div>

    <div class="mt-2 flex" v-if="!isSmallScreen && userRights.right_device_release">
      <div class="m-auto text-sm">Gerätestatus:</div>
      <div class="ml-2 grow">
        <Button
          class="in_use !rounded-r-none"
          :class="{ device_in_use_inactive: !device_free }"
          style="font-size: 0.5rem"
          @click="setDeviceFreeState(false)"
        >
          <span class="p-button-label">belegt</span>
        </Button>
        <Button
          class="device_free !rounded-l-none"
          :class="{ device_free_inactive: device_free }"
          @click="setDeviceFreeState(true)"
        >
          <span class="p-button-label">frei</span>
        </Button>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <DeviceDetailModal v-if="showDeviceDetails" @close="closeDetailModal" :id="device.id" />
  </Teleport>
</template>

<script lang="ts" setup>
  import { formatDateTime } from '@/helper/date'
  import { computed, ref, watch } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { supabase } from '@/supabase'
  import { DeviceWithProperties, GroupWithSupportedProperties, SupportedProperty } from '@/types/extendDatabase'
  import {
    devicePropertiesWithFixedPositions,
    devicePropertyManufacturerTpyeId,
    devicePropertyTypeDesignationTpyeId,
    hideReplayTypeId,
  } from '@/consts'
  import {
    findIndexByPropertyTypeId,
    findPropertyByPropertyTypeId,
    isMaintenanceDate,
    getFormattedValue,
  } from '@/helper/property'
  import { useAuthStore } from '@/store/auth'
  import { useUserDeviceStore } from '@/store/userDevice'
  import { usePropertyStore } from '@/store/property'
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import DeviceDetailModal from './devices/DeviceDetailModal.vue'
  import { useRoute, useRouter } from 'vue-router'

  const props = defineProps({
    device: { type: Object as () => DeviceWithProperties, required: true },
  })

  const router = useRouter()
  const route = useRoute()
  const showDeviceDetails = ref(false)

  if (route.query.showDeviceDetails === 'true') {
    showDeviceDetails.value = true
  }

  const openDetailModal = () => {
    showDeviceDetails.value = true
    router.push({
      path: route.path,
      query: { showDeviceDetails: 'true', ...route.query },
    })
  }

  const closeDetailModal = () => {
    console.log('closeDetailModal')
    if (route.query.showDeviceDetails === 'true') router.back()
    showDeviceDetails.value = false
  }

  watch(
    () => route.query,
    () => {
      if (!route.query.showDeviceDetails) {
        showDeviceDetails.value = false
      } else if (route.query.showDeviceDetails === 'true') {
        showDeviceDetails.value = true
      }
    }
  )

  const userRights = computed(() => useAuthStore().userRights)
  const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)
  const propertyStore = usePropertyStore()
  const deviceGroupStore = useDeviceGroupStore()

  const nextMaintenanceDate = computed(() => {
    const maintenanceDates = props.device.properties
      .filter((p) => isMaintenanceDate(p))
      .sort((a, b) => new Date(a.value_date!).getTime() - new Date(b.value_date!).getTime())
    if (maintenanceDates.length > 0) {
      return maintenanceDates[0]
    }
    return null
  })

  const group: GroupWithSupportedProperties = deviceGroupStore.groupById(props.device.group_id)
  const toast = useToast()

  const device_free = ref(props.device.released ?? false)

  const setDeviceFreeState = async (free: boolean) => {
    device_free.value = free
    const result = await supabase.from('g4y_device').update({ released: free }).eq('id', props.device.id)
    if (result.error) {
      toast.add({ severity: 'error', summary: 'Fehler beim Speichern', life: 3000 })
    } else {
      if (free) {
        toast.add({ severity: 'success', summary: `${props.device.name} frei gemeldet`, life: 3000 })
      } else {
        toast.add({ severity: 'info', summary: `${props.device.name} belegt gemeldet`, life: 3000 })
      }
    }
  }

  const supported_properties = computed<SupportedProperty[][]>(() => {
    const supportedProperties: SupportedProperty[] = group.supported_properties
      .filter((p: SupportedProperty) => !devicePropertiesWithFixedPositions.includes(p.property_type_id))
      .filter((p: SupportedProperty) => p.on_summary_shown)
      .sort((a: SupportedProperty, b: SupportedProperty): number => {
        if (a.on_summary_position_row === b.on_summary_position_row) {
          if (a.on_summary_position_row === null) return 0
          return a.on_summary_position_col! - b.on_summary_position_col!
        } else if (a.on_summary_position_row === null) {
          return 1
        } else if (b.on_summary_position_row === null) {
          return -1
        } else {
          return a.on_summary_position_row! - b.on_summary_position_row!
        }
      })
    if (supportedProperties.length === 0) return []
    let returnValues: SupportedProperty[][] = []
    returnValues.push([])
    let currentRow = 0
    let currentRowInProperties = supportedProperties[0].on_summary_position_row
    for (const supportedProperty of supportedProperties) {
      console.log(
        'supportedProperty row & col',
        supportedProperty.on_summary_position_row,
        supportedProperty.on_summary_position_col
      )
      if (supportedProperty.on_summary_position_row === null) {
        returnValues.push([supportedProperty])
      } else {
        if (supportedProperty.on_summary_position_row !== currentRowInProperties) {
          currentRow++
          currentRowInProperties = supportedProperty.on_summary_position_row
          returnValues.push([])
        }
        returnValues[currentRow].push(supportedProperty)
      }
    }
    console.log('returnValues', returnValues)
    return returnValues
  })

  const getLabel = (supported_property: SupportedProperty): string => {
    return getLabelById(supported_property.property_type_id)
  }
  const getLabelById = (property_type_id: number): string => {
    return propertyStore.propertyTypeLabelById(property_type_id)
  }

  const copyGoogleMapsLink = () => {
    const link = `https://www.google.com/maps/search/?api=1&query=${props.device.position.coordinates[1]},${props.device.position.coordinates[0]}`
    navigator.clipboard.writeText(link)
    toast.add({ severity: 'success', summary: 'GoogleMaps-Link mit Position in Zwischenablage kopiert', life: 3000 })
  }
</script>

<style scoped lang="scss">
  .p-button {
    padding: 0rem;
    height: fit-content;
    width: 50%;
    .p-button-label {
      font-size: 0.8rem !important;
    }
  }

  .in_use {
    background-color: #fa9c9c !important;
    border-color: red !important;
    color: black;
    :hover {
      background-color: red !important;
    }
  }

  .device_in_use_inactive {
    background-color: red !important;
    color: white;
    pointer-events: none;
  }

  .device_free {
    background-color: #89d6a7 !important;
    border-color: green !important;
    color: black;
    :hover {
      background-color: green !important;
    }
  }

  .device_free_inactive {
    background-color: green !important;
    color: white;
    pointer-events: none;
  }
</style>
