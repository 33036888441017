<template>
  <div class="w-full lg:!w-1/2">
    <div>
      <label for="name" class="block text-sm font-medium text-neutral">Name</label>
      <InputText v-model="device.name" id="name" class="w-full" />
    </div>
    <div class="mt-5">
      <label for="group" class="block text-sm font-medium text-neutral">Gruppe</label>
      <Dropdown v-model="device.group_id" :options="groups" option-label="label" optionValue="id" class="w-full" />
    </div>

    <div class="mt-5 flex">
      <label for="active" class="!text-base">Anzeige in Anwendung</label>
      <input v-model="device.active" id="active" type="checkbox" class="ml-3" />
    </div>
    <div class="mt-5 flex" v-if="userRights.right_alerting">
      <label
        for="alerting"
        class="!text-base"
        v-tooltip="'Bei Nutzung außerhalb der erlaubten Nutzungszeit wird eine Alarmmeldung verschickt.'"
        >Alarmierung</label
      >
      <input v-model="device.alerting" id="alerting" type="checkbox" class="ml-3" />
    </div>
    <DevicePictureGallery :deviceId="device.id.toString()" :edit="true" class="mt-8" />
  </div>
  <div class="w-full lg:!w-1/2">
    <div class="mt-2 w-full sm:w-1/2" @click="popupOperatingHours.toggle">
      <span>Betriebsstunden:</span>
      {{ device.operating_hours ? device.operating_hours.toLocaleString('de-de') : 0 }}h
      <button
        class="cursor-pointer text-sm font-light text-gray-700 hover:text-secondary"
        v-if="useAuthStore().userRights.right_device_operating_hours"
      >
        (bearbeiten)
      </button>
      <OverlayPanel ref="popupOperatingHours" showCloseIcon dismissable>
        <OperatingHoursAdmin :deviceId="device.id" class="!bg-white !p-2" />
      </OverlayPanel>
    </div>
    <PropertyEdit
      v-for="supported_property in supported_properties"
      :property_type="propertyStore.propertyTypeById(supported_property.property_type_id)"
      :select-options="
        propertyStore.propertyTypeSelectValueByTypeAndGroupId(supported_property.property_type_id, device.group_id)
      "
      class="mt-5 first:mt-8 lg:first:mt-0"
      v-model="
        device.properties[getIndex(supported_property.property_type_id)][
          getDataTypeLabel(supported_property.property_type_id) ?? 'value_text'
        ]
      "
    />
  </div>
</template>

<script setup lang="ts">
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { usePropertyStore } from '@/store/property'
  import {
    DeviceProperty,
    DeviceWithProperties,
    GroupWithSupportedProperties,
    SupportedProperty,
  } from '@/types/extendDatabase'
  import { cloneDeep } from 'lodash'
  import { computed, reactive, ref, watch } from 'vue'
  import { getDataTypeLabel } from '@/helper/property'
  import PropertyEdit from '@/components/PropertyEdit.vue'
  import { useAuthStore } from '@/store/auth'
  import DevicePictureGallery from '@/components/DevicePictureGallery.vue'
  import { supabase } from '@/supabase'
  import { useDeviceStore } from '@/store/device'
  import OperatingHoursAdmin from '@/components/OperatingHoursAdmin.vue'

  const groupStore = useDeviceGroupStore()
  const propertyStore = usePropertyStore()
  const deviceStore = useDeviceStore()
  const userRights = computed(() => useAuthStore().userRights)
  let isSaving = ref(false)
  const popupOperatingHours = ref()

  const props = defineProps<{
    device: DeviceWithProperties
  }>()

  const device: DeviceWithProperties = reactive(cloneDeep(props.device))

  const group = computed(() => groupStore.groupById(device.group_id))

  const groups = computed<GroupWithSupportedProperties[]>(() => {
    if (!groupStore.groups) return []
    return groupStore.groups.filter((dg) => dg.type === group.value.type)
  })

  const supported_properties = computed<SupportedProperty[]>(() => {
    return group.value.supported_properties.sort(
      (a: SupportedProperty, b: SupportedProperty) =>
        (a.on_detail_page_position_row ?? 0) - (b.on_detail_page_position_row ?? 0)
    )
  })

  watch(
    supported_properties,
    (_newValue) => {
      for (const supported_property of supported_properties.value) {
        const index = device.properties.findIndex((p) => p.property_type_id === supported_property.property_type_id)
        if (index === -1) {
          device.properties.push({
            device_id: device.id,
            property_type_id: supported_property.property_type_id,
          })
        }
      }
    },
    { immediate: true }
  )

  const getIndex = (property_type_id: number): number => {
    return device.properties.findIndex((p) => p.property_type_id === property_type_id)
  }

  const save = async () => {
    if (isSaving.value) return
    isSaving.value = true
    const properties = device.properties.filter((p) => {
      let remove = true
      Object.keys(p).map((key) => {
        if (key.startsWith('value_')) {
          if (p[<keyof DeviceProperty>key] != undefined) remove = false
        }
      })
      return !remove
    })

    const promises: Promise<void>[] = []

    properties.map(async (property) => {
      let promise = new Promise<void>(async (resolve, reject) => {
        if (property[getDataTypeLabel(property.property_type_id)] != undefined) {
          const { error } = await supabase.from('device_property').upsert(property)
          if (error) reject(error)
          resolve()
        }
        resolve()
      })
      promises.push(promise)
    })

    promises.push(
      new Promise<void>(async (resolve, reject) => {
        const { error } = await supabase
          .from('g4y_device')
          .update(
            {
              name: device.name,
              group_id: device.group_id,
              active: device.active,
              alerting: device.alerting,
            },
            { returning: 'minimal' }
          )
          .eq('id', device.id)
        if (error) reject(error)
        resolve()
      })
    )

    const allPromises = Promise.all(promises)
    return new Promise((resolve, reject) => {
      allPromises
        .then(() => {
          deviceStore.updateDeviceFromServerById(device.id)
          console.log('device', device)
          resolve({ message: 'Erfolgreich gespeichert' })
        })
        .catch((error) => {
          console.error(error)
          reject({ error: 'Fehler beim Speichern' })
        })
        .finally(() => {
          isSaving.value = false
        })
    })
  }

  defineExpose({
    save,
  })
</script>
