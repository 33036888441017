import { BLE_TYPE, DEVICE_GROUP_ALL, GPS_TYPE, sortGroupsByTypeAndByName } from '@/helper/filterAndSorting'
import { supabase } from '@/supabase'
import { GroupWithSupportedProperties } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

export const useDeviceGroupStore = defineStore(
  'deviceGroup',
  () => {
    const groups: Ref<GroupWithSupportedProperties[]> = ref([])
    const groupsAsTree: Ref<GroupWithSupportedProperties[]> = ref([])

    async function fetchDeviceGroups() {
      try {
        const { data, error } = await supabase
          .from('g4y_device_group')
          .select('*, supported_properties:device_group_device_property_type(*)')
        if (error) throw error
        const fetchedGroups: GroupWithSupportedProperties[] = [DEVICE_GROUP_ALL].concat(
          data.sort(sortGroupsByTypeAndByName)
        )

        groups.value = fetchedGroups
        groupsAsTree.value = combineGroups(fetchedGroups, [])
      } catch (error) {
        console.error(error)
      }
    }

    function combineGroups(
      groups: GroupWithSupportedProperties[],
      alreadyCombinedGroups: GroupWithSupportedProperties[]
    ): GroupWithSupportedProperties[] {
      if (groups.length === 0) {
        return alreadyCombinedGroups
      } else if (alreadyCombinedGroups.length === 0) {
        const groupsToSort: GroupWithSupportedProperties[] = []
        for (const group of groups) {
          if (!group.parent) {
            alreadyCombinedGroups.push(group)
          } else {
            groupsToSort.push(group)
          }
        }
        return combineGroups(groupsToSort, alreadyCombinedGroups)
      } else {
        const groupsToSort: GroupWithSupportedProperties[] = []
        for (const group of groups) {
          const parentGroup = alreadyCombinedGroups.find((g) => g.id === group.parent)
          if (parentGroup) {
            if (!parentGroup.sub_groups) {
              parentGroup.sub_groups = []
            }
            parentGroup.sub_groups.push(group)
          } else {
            groupsToSort.push(group)
          }
        }
        return combineGroups(groupsToSort, alreadyCombinedGroups)
      }
    }

    ;(() => {
      fetchDeviceGroups()
    })()

    const groupById = computed(() => {
      return (id: number | undefined): GroupWithSupportedProperties => {
        return id !== undefined
          ? groups.value.find((g) => g.id === id) ?? {
              id,
              label: 'exisiteriert nicht',
              type: -1,
              utilization: false,
              supported_properties: [],
            }
          : { id: -1, label: 'exisitiert nicht', type: -1, utilization: false, supported_properties: [] }
      }
    })

    const isBleType = computed(() => {
      return (groupId: number | undefined) => {
        return groups.value.find((g) => g.id === groupId)?.type === BLE_TYPE
      }
    })

    const isGPSType = computed(() => {
      return (groupId: number | undefined) => {
        return groups.value.find((g) => g.id === groupId)?.type === GPS_TYPE
      }
    })

    return { groups, groupsAsTree, groupById, isBleType, isGPSType }
  },
  { persist: true }
)
