<template>
  <TreeSelect v-model="nodeSelected" :options="dataTreeSelect" class="w-full" />
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { GroupWithSupportedProperties } from '@/types/extendDatabase'
  import { filterGrouspByType, DEVICE_GROUP_ALL, Device_Group_Type, TYPE_GROUP_ALL } from '@/helper/filterAndSorting'
  import { TreeNode } from 'primevue/tree'

  const props = defineProps<{
    deviceGroupType?: Device_Group_Type
  }>()

  const selectedGroup = defineModel<GroupWithSupportedProperties>({ required: true })
  const nodeSelected = computed<{ [key: string]: string } | null>({
    get: () => {
      return { [selectedGroup.value.id.toString()]: selectedGroup.value.label }
    },
    set: (newNode) => {
      if (newNode) {
        selectedGroup.value =
          groupStore.groups.find((g) => g.id.toString() === Object.keys(newNode)[0]) ?? DEVICE_GROUP_ALL
      }
    },
  })
  const groupStore = useDeviceGroupStore()

  const groupsAsTree = computed<GroupWithSupportedProperties[]>(() => {
    return groupStore.groupsAsTree.filter(filterGrouspByType(props.deviceGroupType ?? TYPE_GROUP_ALL))
  })

  const deviceGroupsToTreeNodes = (groups: GroupWithSupportedProperties[]): TreeNode[] => {
    const nodes = []
    for (const group of groups) {
      const node: TreeNode = {
        key: group.id.toString(),
        label: group.label,
        children: [],
        data: group,
      }

      if (group.sub_groups) node.children = deviceGroupsToTreeNodes(group.sub_groups)

      nodes.push(node)
    }
    return nodes
  }

  const dataTreeSelect = computed<TreeNode[]>(() => {
    return deviceGroupsToTreeNodes(groupsAsTree.value)
  })
</script>

<!-- FIXME: when primevue is updated use pt  -->
<style>
  .p-tree {
    padding: 0.25rem !important;
    font-size: 0.9rem !important;
  }
  .p-treeselect-label {
    font-size: 0.9rem !important;
  }
  button.p-tree-toggler {
    margin-right: 0 !important;
  }
</style>
