//groups
// 0 - default
// 1 - LKW
// 2 - Bagger
// 3 - Radlader
// 4 - Bus
// 5 - Walze
// 10 - Dumper
// 12 - Grader
// 20 - Minibagger
// 21 - Mobilbagger
// 22-29 - Kettenbagger

import { transform } from 'ol/proj'
import { Circle, Fill, Icon, Stroke } from 'ol/style'
import Style from 'ol/style/Style'

const getStyleByGroupId = (id: number | undefined): Style => {
  switch (id) {
    case 2:
    case 3:
    case 4:
    case 5:
    case 7:
    case 8:
    case 9:
    case 10:
    case 12:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 103:
      return getStyleByGroupIdWithColor(id, [0, 0, 0, 1])
    default:
      return getStyleByGroupIdWithColor(id, [147, 197, 253, 1])
  }
}

//get Icon/Style for device group (and state?)
const getStyleByGroupIdWithColor = (id: number | undefined, color: number[]): Style => {
  let style: Style
  switch (id) {
    case 7:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/truck.svg',
          scale: 0.06,
        }),
      })
      break
    case 10:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/icons/dumper.svg',
          scale: 0.05,
        }),
      })
      break
    case 12:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/icons/grader.svg',
          scale: 0.07,
        }),
      })
      break
    case 20:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/icons/minibagger.svg',
          scale: 0.09,
        }),
      })
      break
    case 21:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/icons/mobilbagger.svg',
          scale: 0.024,
        }),
      })
      break
    case 2:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/excavator.svg',
          scale: 0.06,
        }),
      })
      break
    case 3:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/wheelLoader.svg',
          scale: 0.06,
        }),
      })
      break
    case 4:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/bus.svg',
          scale: 0.06,
        }),
      })
      break
    case 5:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/asphaltgeraete.svg',
          scale: 0.05,
        }),
      })
      break
    case 8:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/erdbauwalze.svg',
          scale: 0.05,
        }),
      })
      break
    case 9:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/asphaltwalze.svg',
          scale: 0.07,
        }),
      })
      break
    case 103:
      style = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color,
          src: '/icons/mulde.svg',
          scale: 0.09,
        }),
      })
      break
    default:
      style = getCirlceStyle(color)
  }
  return style
}

const getCirlceStyle = (color: number[] = [147, 197, 253, 1], radius = 5): Style => {
  const style = new Style({
    image: new Circle({
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color: [30, 58, 138, 1],
      }),
      radius,
    }),
  })
  return style
}

const transformToOl = (coordinates: number[]): number[] => {
  return transform(coordinates, 'EPSG:4326', 'EPSG:3857')
}

const transformFromOl = (coordinates: number[]): number[] => {
  return transform(coordinates, 'EPSG:3857', 'EPSG:4326')
}

const getArrowStyle = (direction: number) => {
  const style = new Style({
    image: new Icon({
      anchorXUnits: 'pixels',
      anchorYUnits: 'fraction',
      crossOrigin: 'anonymous',
      src: '/arrow-right-icon.svg',
      scale: 0.1,
      color: '#ff0000',
      rotation: ((direction - 90) * Math.PI) / 180,
    }),
  })
  return style
}

export { getStyleByGroupId, getStyleByGroupIdWithColor, getCirlceStyle, transformToOl, transformFromOl, getArrowStyle }
